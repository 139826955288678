/* .elementor-widget-container {
  line-height: 1.85714285714286;
  font-family: "Open Sans", sans-serif;

  hyphens: manual;
  border: 0;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    -webkit-border-radius 0.3s, -webkit-box-shadow 0.3s;
  padding: 30px 0px 0px 0px;
}
.hfe-search-form__container {
  border-radius: 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
} */
.hfe-search-button-wrapper {
  display: block;
  cursor: pointer;
  vertical-align: middle;
  text-align: right;
}

.hfe-search-form__container {
  border-width: 1px 1px 1px 1px;
  border-radius: 3px;
}
.search-form__input {
  width: 100%;
}
#clear-with-button {
  visibility: hidden;
  position: absolute;
  top: 0;
  padding: 0 8px;
  font-style: normal;
  font-size: 1.2em;
  user-select: none;
  cursor: pointer;
  bottom: 0;
  background-color: transparent;
}
.hfe-search-submit {
  color: #fff;
}
.elementor-widget-container {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    -webkit-border-radius 0.3s, -webkit-box-shadow 0.3s;
}
.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.hfe-search-submit {
  min-width: 30px;
  background-color: #818a91;
  font-size: 16px;
}
.hfe-search-submit {
  min-width: 3%;
  position: relative;
}
.hfe-search-button-wrapper button {
  border: none;
  border-radius: 0;
  padding: 0;
}

.hfe-search-form__input {
  background-color: #ededed;
}
.hfe-search-form__input {
  padding-left: calc(30px / 5);
  padding-right: calc(30px / 5);
}
/* .hfe-search-form__input {
  width: 100%;
} */
.hfe-search-button-wrapper input[type="search"] {
  border: 0;
  padding: 10px;
  cursor: pointer;

  border-top-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 5px;

  transition: all 0.5s;
  box-sizing: border-box;
  outline: none;
}
/* .hfe-search-button-wrapper input[type="search"] {
  border: 0;
  padding: 10px;
  cursor: pointer;
  border-radius: 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  transition: all 0.5s;
  box-sizing: border-box;
  outline: none;
} */
.hfe-search-button-wrapper button {
  border: none;
  border-radius: 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 0px;
  padding: 10px;
}
.bottom {
  /* margin: 870px; */
  /* text-align: center; */
  color: #54595f;
  border: 0;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  height: 47px;
  /* line-height: 47px; */
  /* text-align: center; */
}

.button:focus{
  outline: 0px !important; 
}

.buttonM{
  padding: 10px 0 10px 0 !important;
}
.buttonM:focus{
  outline: 0px !important; 
}


.date{
  padding: 18.5px 14px !important;
  font: inherit;
    color: currentColor;
    width: -webkit-fill-available;
    position: relative;
    border: 1px solid #B7B7B7;
    border-radius: 4px ;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}
.date:focus {
  border: 1px solid #555 !important; 
}

button:focus{
  outline: 0 !important;
}